/** ******************Freestar Ads********************/
var freestar = freestar || {}
window.freestar = freestar
freestar.hitTime = Date.now()
freestar.queue = freestar.queue || []
freestar.config = freestar.config || {}
freestar.debug = window.location.search.indexOf('fsdebug') !== -1

// Tag IDs set here, must match Tags served in the Body for proper setup
freestar.config.enabled_slots = []

const googleTagInterval = setInterval(() => {
  if (window?.googletag?.apiReady) {
    !(function (a, b) {
      const c = b.getElementsByTagName('script')[0];
        const d = b.createElement('script');
        let e = 'https://a.pub.network/reserveamerica-com'; e += freestar.debug ? '/qa/pubfig.min.js' : '/pubfig.min.js', d.src = e, c.parentNode.insertBefore(d, c)
        // e = 'https://a.pub.network/reserveamerica-com'; e += freestar.debug ? '/qa/pubfig.min.js' : '/pubfig.min.js', d.async = !0, d.src = e, c.parentNode.insertBefore(d, c)
    }(window, document));

    clearInterval(googleTagInterval)
  }
}, 250);

/** ******************Web Chat Shared******************* */
const startWebChat = function () {
  const status = document.getElementById('helpButtonSpan').childNodes[1].innerHTML
  window.embedded_svc.onHelpButtonClick()
}

const getWebChatAgentStatus = function () {
  const chatButton = document.getElementById('helpButtonSpan')
  let agentStatus = ''
  if (typeof chatButton !== 'undefined' && chatButton !== null) {
    agentStatus = chatButton.childNodes[1].innerHTML
  }

  return agentStatus
}

const getWebChatStatus = function () {
  let status = 'disconnected'
  if (typeof window.currWebChatStatus !== 'undefined') {
    status = window.currWebChatStatus
  }

  return status
}

const initWebChatHandlers = function () {
  window.currWebChatStatus = 'disconnected'

  embedded_svc.addEventHandler('onHelpButtonClick', (data) => {
  })

  embedded_svc.addEventHandler('onChatRequestSuccess', (data) => {
  })

  embedded_svc.addEventHandler('onChatEstablished', (data) => {
    window.currWebChatStatus = 'connected'
  })

  embedded_svc.addEventHandler('onChasitorMessage', (data) => {
  })

  embedded_svc.addEventHandler('onAgentMessage', (data) => {
  })

  embedded_svc.addEventHandler('onChatConferenceInitiated', (data) => {
  })

  embedded_svc.addEventHandler('onAgentJoinedConference', (data) => {
  })

  embedded_svc.addEventHandler('onAgentLeftConference', (data) => {
  })

  embedded_svc.addEventHandler('onChatConferenceEnded', (data) => {
  })

  embedded_svc.addEventHandler('onChatTransferSuccessful', (data) => {
  })

  embedded_svc.addEventHandler('onChatEndedByChasitor', (data) => {
    window.currWebChatStatus = 'disconnected'
  })

  embedded_svc.addEventHandler('onChatEndedByAgent', (data) => {
    window.currWebChatStatus = 'disconnected'
  })

  embedded_svc.addEventHandler('onQueueUpdate', (data) => {
  })

  embedded_svc.addEventHandler('onIdleTimeoutOccurred', (data) => {
  })

  embedded_svc.addEventHandler('onConnectionError', (data) => {
  })

  embedded_svc.addEventHandler('onClickSubmitButton', (data) => {
  })
  embedded_svc.addEventHandler('onInviteAccepted', (data) => {
  })

  embedded_svc.addEventHandler('onInviteRejected', (data) => {
  })
}
